<template>
  <div class="z-0">
    <Popover as="template" v-slot="{open}">
      <header
        :class="[
          open ? 'fixed inset-0 z-40 overflow-y-auto' : '',
          'bg-transparent lg:overflow-y-visible'
        ]"
      >
        <div class="mx-auto max-w-7xl">
          <div class="relative flex items-center justify-between lg:gap-8 xl:grid xl:grid-cols-12">
            <div class="flex md:absolute md:inset-y-0 md:left-0 lg:static xl:col-span-2">
              <div class="flex flex-shrink-0 items-center">
                <NuxtLink to="/app/documents">
                  <img class="h-8 w-auto" src="assets/img/logo_wide.png" alt="Paperarchive Logo" />
                </NuxtLink>
              </div>
            </div>
            <div class="flex-1 flex justify-center xl:col-span-8 min-w-96">
              <document-search-bar />
            </div>
            <div class="flex items-center md:absolute md:inset-y-0 md:right-0 lg:hidden">
              <!-- Mobile menu button -->
              <ClientOnly>
                <PopoverButton
                  class="relative -mx-2 inline-flex items-center justify-center rounded-md p-2 text-gray-400 hover:bg-gray-100 hover:text-gray-500 focus:outline-none focus:ring-2 focus:ring-inset focus:ring-indigo-500"
                >
                  <span class="absolute -inset-0.5" />
                  <span class="sr-only">Open menu</span>
                  <Bars3Icon v-if="!open" class="block h-6 w-6" aria-hidden="true" />
                  <XMarkIcon v-else class="block h-6 w-6" aria-hidden="true" />
                </PopoverButton>
              </ClientOnly>
            </div>
            <div class="hidden lg:flex lg:items-center lg:justify-end xl:col-span-2">
              <!-- Add CreditBalance here, before the profile menu -->
              <CreditBalance class="mr-4" />

              <!-- Profile dropdown -->
              <ClientOnly>
                <Menu as="div" class="relative ml-5 flex-shrink-0">
                  <div>
                    <MenuButton
                      class="relative flex rounded-full bg-transparent focus:outline-none focus:ring-2 focus:ring-indigo-500 focus:ring-offset-2"
                    >
                      <span class="absolute -inset-1.5" />
                      <span class="sr-only">Open user menu</span>
                      <UserCircleIcon
                        class="h-7 w-7 rounded-full text-gray-400"
                        aria-hidden="true"
                      />
                    </MenuButton>
                  </div>
                  <transition
                    enter-active-class="transition ease-out duration-100"
                    enter-from-class="transform opacity-0 scale-95"
                    enter-to-class="transform opacity-100 scale-100"
                    leave-active-class="transition ease-in duration-75"
                    leave-from-class="transform opacity-100 scale-100"
                    leave-to-class="transform opacity-0 scale-95"
                  >
                    <MenuItems
                      class="absolute right-0 z-10 mt-2 w-60 origin-top-right rounded-md bg-white py-1 divide-y divide-gray-100 shadow-lg ring-1 ring-black ring-opacity-5 focus:outline-none"
                    >
                      <p class="truncate px-3.5 py-3 border-b border-gray-200" role="none">
                        <span class="block text-xs text-gray-500" role="none">Signed in as</span
                        ><span class="mt-0.5 font-semibold text-sm" role="none">{{
                          userEmail
                        }}</span>
                      </p>
                      <div class="py-1">
                        <MenuItem v-slot="{active}">
                          <a
                            href="/app/billing"
                            :class="[
                              active ? 'bg-gray-100' : '',
                              'block px-4 py-2 text-sm text-gray-700'
                            ]"
                            >Billing</a
                          >
                        </MenuItem>
                        <MenuItem v-slot="{active}">
                          <a
                            href="/app/settings"
                            :class="[
                              active ? 'bg-gray-100' : '',
                              'block px-4 py-2 text-sm text-gray-700'
                            ]"
                            >Settings</a
                          >
                        </MenuItem>
                      </div>
                      <div class="py-1 border-b border-gray-200">
                        <MenuItem v-slot="{active}">
                          <a
                            href="/app/support"
                            :class="[
                              active ? 'bg-gray-100' : '',
                              'block px-4 py-2 text-sm text-gray-700'
                            ]"
                            >Support</a
                          >
                        </MenuItem>
                        <MenuItem v-slot="{active}">
                          <a
                            href="/app/changelog"
                            :class="[
                              active ? 'bg-gray-100' : '',
                              'block px-4 py-2 text-sm text-gray-700'
                            ]"
                            >Changelog</a
                          >
                        </MenuItem>
                        <MenuItem v-slot="{active}">
                          <a
                            href="/app/terms"
                            :class="[
                              active ? 'bg-gray-100' : '',
                              'block px-4 py-2 text-sm text-gray-700'
                            ]"
                            >Terms</a
                          >
                        </MenuItem>
                      </div>
                      <div class="py-1">
                        <MenuItem v-slot="{active}">
                          <a
                            href="/app/logout"
                            :class="[
                              active ? 'bg-gray-100' : '',
                              'block px-4 py-2 text-sm text-gray-700'
                            ]"
                            >Sign out</a
                          >
                        </MenuItem>
                      </div>
                    </MenuItems>
                  </transition>
                </Menu>
              </ClientOnly>
            </div>
          </div>
        </div>

        <PopoverPanel as="nav" class="lg:hidden" aria-label="Global">
          <div class="mx-auto max-w-3xl space-y-1 px-2 pb-3 pt-2 sm:px-4">
            <UploadButton class="ml-3" />
          </div>
          <div class="border-t border-gray-200 pb-3 pt-4">
            <div class="mx-auto flex max-w-3xl items-center px-4 sm:px-6">
              <!-- Add CreditBalance here for mobile -->
              <CreditBalance class="mr-4" />

              <div class="flex-shrink-0">
                <UserCircleIcon class="h-6 w-6 text-gray-400 bg-transparent" aria-hidden="true" />
              </div>
              <div class="ml-3">
                <div class="text-base font-medium text-gray-800">{{ user.name }}</div>
                <div class="text-sm font-medium text-gray-500">{{ user.email }}</div>
              </div>
            </div>
            <div class="mx-auto mt-3 max-w-3xl space-y-1 px-2 sm:px-4 border-t border-gray-200">
              <a
                v-for="item in userNavigation"
                :key="item.name"
                :href="item.href"
                class="block rounded-md px-3 py-2 text-base font-medium text-gray-500 hover:bg-gray-50 hover:text-gray-900"
                :class="{'bg-gray-100': activeRoute === item.href}"
                >{{ item.name }}</a
              >
            </div>
          </div>
        </PopoverPanel>
      </header>
    </Popover>
    <slot />
  </div>
</template>

<script setup>
import {
  Menu,
  MenuButton,
  MenuItem,
  MenuItems,
  Popover,
  PopoverButton,
  PopoverPanel
} from '@headlessui/vue';
import {Bars3Icon, UserCircleIcon, XMarkIcon} from '@heroicons/vue/24/outline';
import CreditBalance from '~/components/CreditBalance.vue';

const router = useRoute();
const authStore = useAuthStore();
const {user} = storeToRefs(authStore);

const userEmail = computed(() => user.value?.email || '');

onMounted(async () => {
  if (!user.value) {
    await authStore.initializeAuth();
  }
});

const userNavigation = [
  {name: 'Billing', href: '/app/billing'},
  {name: 'Settings', href: '/app/settings'},
  {name: 'Changelog', href: '/app/changelog'},
  {name: 'Support', href: '/app/support'},
  {name: 'Sign out', href: '/app/logout'}
];

const activeRoute = router.path;
</script>
